<template>
  <v-container>
    <print-facture v-if="showShowModal && printer === 'ticket'"/>
    <print-facture-letter v-if="showShowModal && printer === 'letter'"/>
    <new-refound v-if="showNewModal"/>
    <v-dialog v-if="sale" v-model="showFinishProcess">
      <v-card>
        <v-card-subtitle>
          <div>
            <v-icon>mdi-database-plus</v-icon>
            <span style="text-transform: uppercase;font-weight: bold"> {{
                $vuetify.lang.t('$vuetify.pay.extra_data')
              }} </span>
          </div>
        </v-card-subtitle>
        <v-card-text>
          <extra-data :from-list="true"
                      :edit="true"
                      :discounts="localDiscounts"
                      :sale="sale"
                      :total-perquisite="parseFloat(sale.totalPerquisite).toFixed(2)"
                      :total-delivery="parseFloat(sale.totalDelivery).toFixed(2)"
                      :taxes="localTaxes"
                      :total-price="parseFloat(sale.totalPrice).toFixed(2)"
                      :total-tax="parseFloat(sale.totalTax).toFixed(2)"
                      :total-discount="parseFloat(sale.totalDiscount).toFixed(2)"
                      :sub-total="parseFloat(sale.subTotal).toFixed(2)"
          />
        </v-card-text>
        <sale-buttons-actions @handleClose="handlerCloseFinishProcess" :sale="sale" :to_route="'hidden'"/>
      </v-card>
    </v-dialog>
    <app-data-table
      :id="id"
      :select-data="selectData"
      :headers="getTableColumns"
      csv-filename="SaleProducts"
      :manager="'sales'"
      :items="sales"
      :options="vBindOption"
      :sort-by="['no_facture']"
      :sort-desc="[false, true]"
      :viewShowFilterDate="true"
      multi-sort
      :multi-select=true
      :is-loading="isTableLoading"
      @reload-data="reloadData($event)"
      @create-row="createSaleHandler"
      @delete-row="deleteSaleHandler($event)"
      @edit-row="editSaleHandler($event)"
    >
      <template v-slot:[`item.no_facture`]="{ item }">
        <template>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <b>
                <v-icon
                  style="color: red"
                  class="mr-2"
                  small
                  v-bind="attrs"
                  @click="openPrintModal('ticket',item.id)"
                  v-on="on"
                >
                  mdi-printer
                </v-icon>
              </b>
            </template>
            <span>{{ $vuetify.lang.t("$vuetify.report.print_ticket") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <b>
                <v-icon
                  style="color: #1a5dd6"
                  class="mr-2"
                  small
                  v-bind="attrs"
                  @click="openPrintModal('letter', item.id)"
                  v-on="on"
                >
                  mdi-printer-settings
                </v-icon>
              </b>
            </template>
            <span>{{ $vuetify.lang.t("$vuetify.report.print_letter") }}</span>
          </v-tooltip>
        </template>
        {{ item.no_facture }}
      </template>
      <template v-slot:[`item.state_text`]="{ item }">
        <template v-if="item.state !== 'preform' && item.state !== 'cancelled'">
          <v-autocomplete
            v-model="item.state"
            chips
            :items="getLocalStates(item)"
            item-text="text"
            item-value="value"
            :style="{ width: '160px' }"
            @input="changeState(item)"
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.item.value"
                @click="data.select"
              >
                <i :style="'color: ' + data.item.color">
                  <v-icon left>
                    {{ data.item.icon }}
                  </v-icon>
                  {{ data.item.text }}</i>
              </v-chip>
            </template>
            <template v-slot:item="data">
              <template
                v-if="typeof data.item !== 'object'"
              >
                <v-list-item-content
                  v-text="data.item"
                />
              </template>
              <template v-else>
                <v-list-item-icon>
                  <v-icon
                    left
                    :style="'color: ' + data.item.color"
                  >
                    {{ data.item.icon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    :style="
                          'color: ' + data.item.color
                        "
                  >
                    {{ data.item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </template>
        <span :style="item.state === 'cancelled' ? 'color: red' : 'color: green'" v-else>{{ item.state_text }}</span>
      </template>
      <template v-slot:[`item.type_order`]="{ item }">
        <span v-if="item.type_order"> {{ item.type_order.name }}</span>
        <span v-else> --- </span>
      </template>
      <template v-slot:[`item.margin`]="{ item }">
        {{ parseFloat(item.margin).toFixed(2) + ' ' + getCurrency }}
      </template>
      <template v-slot:[`item.subTotal`]="{ item }">
        {{ parseFloat(item.subTotal).toFixed(2) + ' ' + getCurrency }}
      </template>
      <template v-slot:[`item.totalPrice`]="{ item }">
        <v-tooltip v-if="item.discounts.length > 0 || item.taxes.length > 0 || item.totalRefund || item.delivery || item.perquisite">
          <template
            v-slot:activator="{ on, attrs }"
          >
            <b>
              <v-icon
                style="color: red"
                class="mr-2"
                small
                v-bind="attrs"
                v-on="on">
                mdi-information
              </v-icon>
            </b>
            <template v-if="parseFloat(item.totalRefund).toFixed(2) !== '0.00'">
              <span class="text-decoration-line-through"> ({{
                  parseFloat(item.totalPrice + item.totalRefund).toFixed(2) + ' ' + getCurrency
                }})</span>
              <span> {{ parseFloat(item.finalPrice).toFixed(2) + ' ' + getCurrency }}</span>
            </template>
            <template v-else>
              <span> {{ parseFloat(item.finalPrice).toFixed(2) + ' ' + getCurrency }}</span>
            </template>
          </template>
          <facture :sale="item" :sub-total="item.subTotal" :total-tax="item.totalTax" :total-delivery="item.totalDelivery"
                   :total-perquisite="item.totalPerquisite" :total-discount="item.totalDiscount" :total-price="item.totalPrice"/>
        </v-tooltip>
        <span v-else> {{ parseFloat(item.finalPrice).toFixed(2) + ' ' + getCurrency }}</span>
      </template>
      <template v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }">
        <v-btn
          v-if="item.sale_article_shops.length > 0"
          color="primary"
          fab
          x-small
          dark
          @click="expand(!isExpanded)"
        >
          <v-icon v-if="isExpanded">
            mdi-chevron-up
          </v-icon>
          <v-icon v-else>
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          style="padding: 0 0 0 0"
        >
          <product-sale :item="item"/>
          <facture v-if="item.discounts.length > 0 || item.taxes.length > 0"
                   :show-facture="false"
                   :sale="item" :sub-total="item.subTotal"
                   :total-discount="item.totalDiscount"
                   :total-tax="item.totalTax"
                   :total-price="item.totalPrice"/>
        </td>
      </template>
    </app-data-table>
  </v-container>
</template>
<script>

import { mapActions, mapGetters, mapState } from 'vuex'
import Facture from '../../facture/Facture'
import PrintFacture from './PrintFacture'
import PrintFactureLetter from './PrintFactureLetter'
import NewRefound from '../../refund/commerce/NewRefund'
import ExtraData from './manager-sale/ExtraData'
import SaleButtonsActions from './manager-sale/SaleButtonsActions'
import ProductSale from './list-sale/ProductSale'

export default {
  name: 'ListSale',
  components: {
    ProductSale,
    SaleButtonsActions,
    ExtraData,
    NewRefound,
    PrintFactureLetter,
    PrintFacture,
    Facture
  },
  data () {
    return {
      sale: null,
      localTaxes: [],
      localDiscounts: [],
      showFinishProcess: false,
      tab: null,
      filter: null,
      items: [{
        text: this.$vuetify.lang.t('$vuetify.titles.list', [
          this.$vuetify.lang.t('$vuetify.menu.vending')
        ])
      }],
      selectData: [],
      loadingData: false,
      id: 'Sale',
      menu: false,
      fav: true,
      message: false,
      hints: true,
      search: '',
      localAccess: {},
      printer: '',
      vBindOption: {
        itemKey: 'no_facture',
        singleExpand: false,
        showExpand: true
      }
    }
  },
  props: {
    state: {
      type: String,
      default: 'open'
    }
  },
  computed: {
    ...mapState('sale', ['editSale', 'showEditModal', 'loadData', 'showShowModal', 'sales',
      'isTableLoading']),
    ...mapState('tax', ['taxes']),
    ...mapState('discount', ['discounts']),
    ...mapState('refund', ['showNewModal']),
    ...mapGetters('auth', ['user', 'access_permit']),
    ...mapGetters('auth', ['isManagerIn']),
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ''
    },
    getTableColumns () {
      const data = [
        {
          text: this.$vuetify.lang.t('$vuetify.tax.noFacture'),
          value: 'no_facture',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.state'),
          value: 'state_text',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.menu.type_order'),
          value: 'type_order',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.menu.table'),
          value: 'table',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.report.grossPrice'),
          value: 'subTotal',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.variants.total_price'),
          value: 'totalPrice',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.menu.shop'),
          value: 'shop.name',
          select_filter: true
        }
      ]
      if (this.isManagerIn) {
        data.push(
          {
            text: this.$vuetify.lang.t('$vuetify.articles.percent'),
            value: 'percent',
            select_filter: true
          }, {
            text: this.$vuetify.lang.t('$vuetify.report.margin') + '($)',
            value: 'margin',
            select_filter: true
          }
        )
      }
      data.push({
        text: this.$vuetify.lang.t('$vuetify.actions.actions'),
        value: 'actions',
        sortable: false
      })
      return data
    }
  },
  async mounted () {
    await this.getTaxes()
    await this.getDiscounts()
    this.switchLoadData(false)
  },
  methods: {
    ...mapActions('sale', ['toogleNewModal', 'openEditModal', 'openShowModal', 'getSales', 'updateSale',
      'changeStateSale', 'switchLoadData', 'deleteSale', 'multiDelete', 'getSalesFilter']),
    ...mapActions('refund', ['openNewModal']),
    ...mapActions('tax', ['getTaxes']),
    ...mapActions('discount', ['getDiscounts']),
    updateLocalTaxes () {
      this.localTaxes = this.taxes
      if (this.sale.sale_article_shops.length > 0) {
        this.sale.sale_article_shops.forEach((artS) => {
          artS.sale_article_taxes.forEach((v) => {
            this.localTaxes = this.localTaxes.filter(t => t.id !== v.tax_id && t.id !== v.id)
            this.sale.taxes = this.sale.taxes.filter(t => t.id !== v.tax_id && t.id !== v.id)
          })
        })
      }
    },
    getLocalStates (item) {
      return item.state === 'open'
        ? [
          {
            text: this.$vuetify.lang.t(
              '$vuetify.sale.state.open'
            ),
            value: 'open',
            icon: 'mdi-star-half',
            color: '#4caf50'
          },
          {
            text: this.$vuetify.lang.t(
              '$vuetify.sale.state.accepted'
            ),
            value: 'accepted',
            icon: 'mdi-star',
            color: '#3f51b5'
          },
          {
            text: this.$vuetify.lang.t(
              '$vuetify.sale.state.cancelled'
            ),
            value: 'cancelled',
            icon: 'mdi-star-off',
            color: '#ff0000'
          }
        ]
        : [
          {
            text: this.$vuetify.lang.t(
              '$vuetify.sale.state.accepted'
            ),
            value: 'accepted',
            icon: 'mdi-star',
            color: '#3f51b5'
          },
          {
            text: this.$vuetify.lang.t(
              '$vuetify.sale.state.cancelled'
            ),
            value: 'cancelled',
            icon: 'mdi-star-off',
            color: '#ff0000'
          }
        ]
    },
    changeState (item) {
      if (item.state === 'accepted') {
        this.localTaxes = this.taxes
        if (item.sale_article_shops.length > 0) {
          item.sale_article_shops.forEach((artS) => {
            artS.sale_article_taxes.forEach((v) => {
              this.localTaxes = this.localTaxes.filter(t => t.id !== v.tax_id && t.id !== v.id)
              item.taxes = item.taxes.filter(t => t.id !== v.tax_id && t.id !== v.id)
            })
          })
        }
        this.localDiscounts = this.discounts
        if (item.sale_article_shops.length > 0) {
          item.sale_article_shops.forEach((artS) => {
            artS.discounts.forEach((v) => {
              this.localDiscounts = this.localDiscounts.filter(t => t.id !== v.tax_id && t.id !== v.id)
              item.taxes = item.taxes.filter(t => t.id !== v.tax_id && t.id !== v.id)
            })
          })
        }
        item.state = 'open'
        item.state_text = this.$vuetify.lang.t('$vuetify.sale.state.open')
        this.sale = item
        this.showFinishProcess = true
      }
      /* else { this.changeStateSale({ id: item.id, state: item.state }) } */
    },
    handlerCloseFinishProcess () {
      this.showFinishProcess = false
      // window.location.reload()
    },
    openPrintModal (print, id) {
      this.printer = print
      this.openShowModal(id)
    },
    reloadData (filter) {
      this.filter = filter
      this.filter[2] = this.state
      this.loadInitData()
    },
    async loadInitData () {
      this.$store.state.sale.sales = []
      await this.getSalesFilter(this.filter)
      this.switchLoadData(false)
      // this.loadingData = false
    },
    createSaleHandler () {
      this.$store.state.sale.managerSale = false
      this.$store.dispatch('sale/restoreNewSale')
      this.$router.push({ name: 'vending_new' })
    },
    editSaleHandler ($event) {
      this.$router.push({ name: 'vending_edit', params: { hash: btoa($event) } })
    },
    refundArticle (sale, article) {
      if (
        article.cant === article.cantRefund.cant || article.totalPrice === article.cantRefund.money
      ) {
        this.showMessage(
          this.$vuetify.lang.t('$vuetify.actions.refund', [
            this.$vuetify.lang.t('$vuetify.menu.articles')
          ]),
          this.$vuetify.lang.t('$vuetify.messages.warning_refund_all')
        )
      } else {
        this.openNewModal({ sale, article })
      }
    },
    deleteSaleHandler (articleId) {
      this.$Swal
        .fire({
          title: this.$vuetify.lang.t('$vuetify.titles.delete', [
            this.$vuetify.lang.t('$vuetify.sale.sale')
          ]),
          text: this.$vuetify.lang.t(
            '$vuetify.messages.warning_delete'
          ),
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.cancel'
          ),
          confirmButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.delete'
          ),
          confirmButtonColor: 'red'
        })
        .then(result => {
          if (result.isConfirmed) {
            this.deleteSale(articleId).then(() => {
              this.loadInitData()
            })
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
